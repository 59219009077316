
<template>
    <div class="blogger-post">
      <a href="#" @click="backClicked" class="back-link">&lt;返回</a>
      <div v-if="post !== null" class="py-3">
        <h5 class="text-center" v-text="post.title"></h5>
        <div class="post-content" v-html="post.content"></div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  
  export default {
    name: 'TopicContent',
    data() {
      return {
        readBusyName: 'READPOSTCONTENT',
        post: null,
      };
    },
    props: {
    },
    components: {
    },
    mounted() {
      const { id } = this.$route.params;
      this.appendComponentBusy(this.readBusyName);
      this.$store.dispatch('api/readPostDetailPromise', id).then((d) => {
				this.post = Object.assign({}, this.post, d);
			}).catch((e) => {
        this.appendErrorMsg(e);
			}).then(() => {
        this.clearComponentBusy(this.readBusyName);
			});
    },
    methods: {
      ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
      backClicked() {
        this.$router.go(-1);
      },
    }
  }
  </script>
  
  <style scoped>
    :root {
      --lh: 1.8em;
    }
  
    .back-link {
      color: #C4C4C4;
      font-size: 1em;
      text-decoration: underline;
    }

    .blogger-post {
      padding: 0 2rem;
    }
  
    .post-content {
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      overflow-wrap: break-word;
      word-break: break-word;
      word-wrap: break-word;
  
      line-height: var(--lh) ;
    }
  </style>
  
  <style>
    .post-content div {
      line-height: var(--lh) !important;
    }
  
    .post-content a {
      margin-left: 0!important;
      margin-right: 0!important;
    }
  
    .post-content span {
      margin-left: 0!important;
      margin-right: 0!important;
      line-height: var(--lh) !important;
    }
  
    .post-content p {
      line-height: 2em !important;
    }
  
    .post-content img {
      max-width: 100%;
      height: inherit;
    }
  </style>
  